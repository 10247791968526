@font-face {
  font-family: "NoeDisplay-Medium";
  src: url("../../fonts/NoeDisplay-Medium.ttf") format("truetype");
}

.conatct_wrap {
  min-height: 100vh;
  background-color: #f7f7f7;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > p {
    font-family: "NoeDisplay-Medium";
    font-size: 35px;
    text-align: left;
  }
  > .social {
    > a {
      text-decoration: none;
      color: black;
      transition: all 0.3s;
      > svg {
        font-size: 25px;
        margin-right: 15px;
        cursor: pointer;
        margin-top: 20px;
      }
      &:hover {
        color: rgb(163, 161, 161);
      }
    }

    > a:nth-child(2) {
      &:hover {
        color: rgb(50, 110, 241);
      }
    }
    > a:nth-child(3) {
      &:hover {
        color: rgb(161, 188, 245);
      }
    }
    > a:nth-child(4) {
      &:hover {
        color: rgb(207, 8, 35);
      }
    }
  }
}

/* 
 */
