@font-face {
  font-family: "NoeDisplay-Medium";
  src: url("../../fonts/NoeDisplay-Medium.ttf") format("truetype");
}

.about_wrap {
  min-height: 50vh;
  background-color: #f7f7f7;
  padding: 10px 5px;
  .wrap {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > p {
      font-family: "NoeDisplay-Medium";
      font-size: calc(0.9rem + 0.5vw);
      text-align: left;
      width: 70%;
      letter-spacing: 1.7px;
      line-height: 1.5;
      @media screen and (max-width: 600px) {
        width: 90%;
      }
    }
    > p:first-child {
      margin-bottom: 30px;
    }
    > p:last-child {
    }
  }
  > .title {
    text-align: center;

    > p {
      font-size: 40px;
      display: inline-block;
      font-family: "NoeDisplay-Medium";
      margin-top: 50px;
      margin-bottom: 30px;
      letter-spacing: 2.5px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 30px;
        height: 2px;
        background-color: #bababa;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

/* 
   */
