*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{

}
.App{
  position: relative;
  min-height: 100vh;
}
.App .container{
  width:100%;
  margin: auto;
  overflow: auto;
}
@media screen and (max-width:730px) {
  .App .container{
    width:99%;
    margin: auto;
    overflow: auto;
  }
}