@import url("https://fonts.googleapis.com/css2?family=Goldman:wght@400;700&display=swap");
@font-face {
  font-family: "MarkPro-Medium";
  src: url("../../fonts/MarkPro-Medium.ttf") format("truetype");
}
nav {
  width: 90%;
  margin: auto;
  > .nav_content {
    margin-top: 60px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .logo {
      flex: 1 1 35%;
      > p {
        font-size: 45px;
        letter-spacing: 1.9px;
        color: black;
        font-family: "Goldman", cursive;
      }
      > h6 {
        font-family: "Goldman", cursive;
        text-align: left;
        font-size: 11px;
        font-weight: 100;
      }
    }
    > .nav_links {
      flex: 1 1 30%;

      > ul {
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        > li {
          > a {
            font-family: "MarkPro-Medium";
            display: inline-block;
            padding: 20px 9px;
            text-decoration: none;
            font-size: 17px;
            color: black;
            transition: all 0.2s;
            &:hover {
              color: gray;
            }
          }
        }
      }
    }
    > svg {
      display: none;
    }
    @media screen and (max-width: 700px) {
      svg {
        display: block;
        font-size: 25px;
        opacity: 0.7;
        cursor: pointer;
      }
      .nav_links {
        display: none;
      }
    }
  }
  > .small_menu {
    display: none;
  }
  @media screen and (max-width: 700px) {
    > .small_menu {
      display: block;
      position: absolute;
      // height: 100vh;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: rgba(211, 211, 211, 0.837);
      opacity: 1;
      visibility: visible;
      transition: all 0.3s;
      z-index: 100;
      > ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        padding-top: 70px;

        > li {
          margin-bottom: 15px;
          text-align: center;

          > a {
            text-decoration: none;
            display: block;
            padding: 20px 9px;
            text-decoration: none;
            font-size: 19px;
            color: black;
            transition: all 0.2s;
            &:hover {
              color: gray;
            }
          }
        }
      }
      > svg {
        position: absolute;
        top: 50px;
        right: 20px;
        cursor: pointer;
      }
    }

    > .small_menu.close {
      visibility: hidden;
      opacity: 0;
    }
  }
}
