.work_section {
  margin-bottom: 5px;
  > .title {
    width: 90%;
    margin: 50px auto;
    > p {
      font-size: 40px;
      font-weight: 600;
      color: #242a35;
      line-height: 1.4;
      letter-spacing: 2.5px;
      width: 70%;
      @media screen and (max-width: 700px) {
        width: 100%;
        font-size: 35px;
      }
      @media screen and (max-width: 500px) {
        width: 100%;
        font-size: 28px;
      }
      @media screen and (max-width: 350px) {
        font-size: 24px;
      }
    }
  }
  > .item {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 10px 0;
    width: 100%;
    > .large {
      flex: 1 1 65%;
      order: 1;
      // height: 500px;
      position: relative;
      // @media screen and (max-width: 700px) {
      //   height: 400px;
      // }
      // @media screen and (max-width: 600px) {
      //   height: 300px;
      // }
      // @media screen and (max-width: 450px) {
      //   height: 200px;
      // }
      // @media screen and (max-width: 350px) {
      //   height: 150px;
      // }
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      > .overlayer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(24, 23, 23, 0.45);
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s;
        cursor: pointer;
        > h4 {
          color: white;
          font-size: calc(1rem + 0.5vw);
          letter-spacing: 1.5px;
        }
      }
      &:hover .overlayer {
        visibility: visible;
        opacity: 1;
      }
    }
    > .large-mid {
      flex: 1 1 50%;
      // height: 500px;
      position: relative;
      // @media screen and (max-width: 700px) {
      //   height: 400px;
      // }
      // @media screen and (max-width: 600px) {
      //   height: 300px;
      // }
      // @media screen and (max-width: 450px) {
      //   height: 200px;
      // }
      // @media screen and (max-width: 350px) {
      //   height: 150px;
      // }
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      > .overlayer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(24, 23, 23, 0.45);
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s;
        cursor: pointer;
        > h4 {
          color: white;
          font-size: calc(1rem + 0.5vw);
          letter-spacing: 1.5px;
        }
      }
      &:hover .overlayer {
        visibility: visible;
        opacity: 1;
      }
    }
    > .small {
      flex: 1 1 auto;
      max-width: 35%;
      order: 2;

      // height: 600px;
      // @media screen and (max-width: 700px) {
      //   height: 400px;
      // }
      // @media screen and (max-width: 600px) {
      //   height: 300px;
      // }
      // @media screen and (max-width: 450px) {
      //   height: 200px;
      // }
      // @media screen and (max-width: 350px) {
      //   height: 150px;
      // }

      > div {
        height: 50%;
        position: relative;

        > img {
          width: 100%;
          height: 100%;
          display: block;
          // object-fit: cover;
        }
        > .overlayer {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(24, 23, 23, 0.45);
          z-index: 10;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0;
          visibility: hidden;
          transition: all 0.5s;
          cursor: pointer;
          > h4 {
            color: white;
            font-size: calc(1rem + 0.5vw);
            letter-spacing: 1.5px;
          }
        }
        &:hover .overlayer {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    > .small-mid {
      flex: 1 1 50%;
      // height: 500px;
      // @media screen and (max-width: 700px) {
      //   height: 400px;
      // }
      // @media screen and (max-width: 600px) {
      //   height: 300px;
      // }
      // @media screen and (max-width: 450px) {
      //   height: 200px;
      // }
      // @media screen and (max-width: 350px) {
      //   height: 150px;
      // }

      > div {
        height: 100%;
        position: relative;

        > img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
        }
        > .overlayer {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(24, 23, 23, 0.45);
          z-index: 10;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0;
          visibility: hidden;
          transition: all 0.5s;
          cursor: pointer;
          > h4 {
            color: white;
            font-size: calc(1rem + 0.5vw);
            letter-spacing: 1.5px;
          }
        }
        &:hover .overlayer {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    > .large-only {
      flex: 1 1 100%;
      // height: 500px;
      position: relative;
      // @media screen and (max-width: 700px) {
      //   height: 400px;
      // }
      // @media screen and (max-width: 600px) {
      //   height: 300px;
      // }
      // @media screen and (max-width: 450px) {
      //   height: 200px;
      // }
      // @media screen and (max-width: 350px) {
      //   height: 150px;
      // }
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      > .overlayer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(24, 23, 23, 0.45);
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s;
        cursor: pointer;
        > h4 {
          color: white;
          font-size: calc(1rem + 0.5vw);
          letter-spacing: 1.5px;
        }
      }
      &:hover .overlayer {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  > .item:nth-child(odd) {
    > .large {
      order: 2;
    }
    > .small {
      order: 1;
    }
  }
}
