@font-face {
  font-family: "MarkPro-Medium";
  src: url("../../fonts/MarkPro-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "NoeDisplay-Medium";
  src: url("../../fonts/NoeDisplay-Medium.ttf") format("truetype");
}

.work_item {
  max-width: 100%;
  margin: 50px auto;
  text-align: center;
  > .titile {
    width: 75%;
    margin: auto;

    @media screen and (max-width: 465px) {
      display: block;
      width: 95%;
    }

    > h3 {
      font-family: "NoeDisplay-Medium";
      text-align: center;
      font-size: 30px;
      letter-spacing: 2.9px;
      margin-bottom: 15px;
      @media screen and (max-width: 500px) {
        font-size: 28px;
      }
      @media screen and (max-width: 465px) {
        margin-bottom: 20px;
      }
    }
    > p {
      font-family: "MarkPro-Medium";
      color: rgb(27, 27, 27);
      position: relative;
      font-size: 18px;
      font-weight: 100;
      text-align: left;
      line-height: 1.7;
      font-weight: 500;
      @media screen and (max-width: 700px) {
      }
      @media screen and (max-width: 465px) {
        width: 100%;
        max-width: 100%;
      }
      &:after {
        position: absolute;
        content: "";
        width: 20px;
        height: 2px;
        background-color: #bababa;
        bottom: -15px;
        left: 0;
      }
    }
    > .brand {
      margin-top: 35px;
      display: flex;
      gap: 15px;
      > p {
        font-family: "MarkPro-Medium";

        font-size: 15px;
        color: rgb(102, 102, 102);
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          color: black;
        }
      }
    }
  }
  > .images {
    max-width: 90%;
    margin: 25px auto;
    > img {
      max-width: 100%;
      object-fit: cover;
      margin-bottom: 15px;
      display: block;
    }
    > .two_img {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      align-content: center;
      gap: 10px;
      height: 350px;
      @media screen and (max-width: 700px) {
        height: 200px;
      }
      @media screen and (max-width: 500px) {
        height: 150px;
      }
      @media screen and (max-width: 400px) {
        height: 100px;
      }
      > img {
        width: 35%;
        max-width: 35%;
        display: block;
        object-fit: cover;
      }
      > img:nth-child(odd) {
        width: 65%;
        max-width: 65%;
        display: block;
        object-fit: cover;
      }
    }
  }
}
